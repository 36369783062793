// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';
// @import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@300;400;500;600;700&display=swap');
@import "assets/plugins/custom/fullcalendar/fullcalendar.bundle.css";
@import "assets/plugins/global/plugins.bundle.css";
@import "assets/plugins/custom/prismjs/prismjs.bundle.css";
@import "assets/css/style.bundle.css";
@import "assets/css/themes/layout/header/base/light.css";
@import "assets/css/themes/layout/header/menu/light.css";
@import "assets/css/themes/layout/brand/light.css";
@import "assets/css/themes/layout/aside/light.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$job2go-wmt-primary: mat.define-palette(mat.$indigo-palette);
$job2go-wmt-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$job2go-wmt-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$job2go-wmt-theme: mat.define-light-theme((
  color: (
    primary: $job2go-wmt-primary,
    accent: $job2go-wmt-accent,
    warn: $job2go-wmt-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($job2go-wmt-theme);

@font-face {
  font-family: 'Gotham Rounded';
  src: url("./assets/plugins/global/fonts/gotham-rounded/GothamRoundedBold_21016.ttf")
      format("truetype"),
    url("./assets/plugins/global/fonts/gotham-rounded/GothamRounded-Bold.otf")
      format("otf")
}
@font-face {
  font-family: 'Gotham Rounded Book';
  src: url("./assets/plugins/global/fonts/gotham-rounded/GothamRoundedBook_21018.ttf")
      format("truetype"),
    url("./assets/plugins/global/fonts/gotham-rounded/GothamRounded-Book.otf")
      format("otf"),
    url("./assets/plugins/global/fonts/gotham-rounded/GothamRounded-BookItalic.otf")
      format("otf"),
}
@font-face {
  font-family: 'Gotham Rounded Medium';
  src: url("./assets/plugins/global/fonts/gotham-rounded/GothamRoundedMedium_21022.ttf")
      format("truetype"),
    url("./assets/plugins/global/fonts/gotham-rounded/GothamRounded-Medium.otf")
      format("otf"),
    url("./assets/plugins/global/fonts/gotham-rounded/GothamRounded-MediumItalic.otf")
      format("otf");
}

body {
  overflow-x: hidden;
  background-color: #F9FAFB;
}

.special-date {
  color: #E43134 !important;
  background-color: #f7dbd6 !important;
  border-radius: 200px !important;
}

.ff-gotham {
  font-family: 'Gotham Rounded';
}
.ff-gotham-book {
  font-family: 'Gotham Rounded Book';
}
.ff-gotham-medium {
  font-family: 'Gotham Rounded Medium';
}

.card {
  border: none;
}

// Buat AGM Core Dialog
.pac-container {
  z-index: 9999;
}

// J2G-1848 - FE WMT Dashboard UI
@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 65px;
  }
  .header {
    background-color: #F9FAFB;
  }
  .footer-fixed .content {
    padding-bottom: 80px !important;
  }
  .footer-fixed .footer {
    box-shadow: none !important;
  }
}

.theme-loader {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999999;
}

@keyframes ball-scale {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes ball-scale {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ball-scale {
  left: 50%;
  top: 50%;
  position: absolute;
  height: 100px;
  width: 100px;
  margin: -50px 0 0 -50px;
  > div {
    -webkit-animation: ball-scale 2s linear infinite;
    animation: ball-scale 2s linear infinite;
    border: 5px solid #0073aa;
    border-top-color: transparent;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: relative;
    &:before, &:after {
      -webkit-animation: ball-scale 1s linear infinite;
      animation: ball-scale 1s linear infinite;
      content: "";
      position: absolute;
      border: 5px solid #0073aa;
      border-top-color: transparent;
      border-radius: 50%;
    }
    &:before {
      height: 80%;
      width: 80%;
      top: 10%;
      left: 10%;
    }
    &:after {
      -webkit-animation: ball-scale 0.5s linear infinite;
      animation: ball-scale 0.5s linear infinite;
      height: 50%;
      width: 50%;
      top: 25%;
      left: 25%;
    }
  }
}

.ball-scale > div {
  background: #fff;
}

agm-map, vector_map {
  width: 100%;
  height: 350px;
  display: flex;
}

.switch {
  vertical-align: middle !important;
}

.tag__text {
  line-height: 2;
  font-size: 11px;
  font-weight: 600;
}

delete-icon svg {
  height: 13px !important;
  line-height: 1;
  vertical-align: top !important;
  margin-top: 5px;
}

.ng2-tags-container tag {
  padding: 0 5px !important;
  height: 22px !important;
}

delete-icon:hover {
  transform: none !important;
}

.bootstrap tag {
  background: #0073aa !important;
}

// ngb-pagination .pagination .page-item a[aria-label=First] {
//   background: rgba(93, 120, 255, 0.1);
// }
// ngb-pagination .pagination .page-item a[aria-label=Previous] {
//   background: rgba(93, 120, 255, 0.1);
// }
// ngb-pagination .pagination .page-item a[aria-label=Next] {
//   background: rgba(93, 120, 255, 0.1);
// }
// ngb-pagination .pagination .page-item a[aria-label=Last] {
//   background: rgba(93, 120, 255, 0.1);
// }
// ngb-pagination .pagination .page-item a:hover {
//   background-color: #DE302B !important;
// }

.modal-backdrop {
  z-index: 1040 !important;
}

// Sticky Table
.table .sticky {
  position: sticky;
  z-index: 4 !important;
}

// Snackbar Custom Color
.snackbar-notif {
  background: white;
  color: black;
}
.snackbar-success {
  background: #EEFFEF;
  color: #42CC75;
  border: 1px solid #42CC75;
}
.snackbar-danger {
  background: #FFF6F6;
  color: #FA3C3C;
  border: 1px solid #FA3C3C;
}
.snackbar-warning {
  background-color: #FFFFF6;
  color: #F5C700;
  border: 1px solid #FECE00;
}
.snackbar-info {
  background-color: #EEE5FF;
  color: #8950FC;
  border: 1px solid #8950FC;
}

.snackbar-success, .snackbar-danger, .snackbar-warning, .snackbar-info {
  box-shadow: none;
}

// Label Custom Style
.label-light-red {
  color: #FA3C3C;
  background-color: #FFF3F3;
}
.label-light-yellow {
  color: #FFC700;
  background-color: #FFF8DD;
}
.label-light-blue {
  color: #008BD3;
  background-color: #EFFAFF;
}
.label-light-green {
  color: #42CC75;
  background-color: #F6FFF9;
}

// Button Custom Style
.btn-secondary {
  color: #FFFFFF !important;
  background-color: #5C5E76 !important;
}
.btn-reject {
  color: #FFFFFF;
  background-color: #FA3C3C;
}
.btn-approve {
  color: #FFFFFF;
  background-color: #42CC75;
}
.btn-disburse {
  color: #FFFFFF;
  background-color: #5D5FEF;
}
.btn-process {
  color: #FFFFFF;
  background: #5C5E76;
}
.btn-process:hover {
  color: #FFFFFF;
}

// Background Custom Style
.bg-green {
  background-color: #38D687;
}
.bg-lime {
  background-color: #47CCC8;
}
.bg-mocha {
  background-color: #9E8A78;
}
.bg-pink {
  background-color: #FD698C;
}
.bg-secondary {
  background-color: #5C5E76;
}


// Border Custom Style
.border-green {
  border-color: #38D687 !important;
}
.border-lime {
  border-color: #47CCC8 !important;
}
.border-mocha {
  border-color: #9E8A78 !important;
}
.border-pink {
  border-color: #FD698C !important;
}


// Font Custom Style
.text-secondary {
  color: #717171 !important;
}
.text-green {
  color: #42CC75 !important;
}
.text-red {
  color: #DD3333 !important;
}
.text-reject {
  color: #FA3C3C !important;
}
.text-process {
  color: #008BD3 !important;
}
.text-grey {
  color: #B3B3B3;
}
.text-caret {
  color: #0000008a;
}

// Svg Icon
.svg-icon.svg-icon-process svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #008BD3 !important;
}
.svg-icon.svg-icon-green svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #42CC75 !important;
}

// Custom Background
.bg-neutral {
  background-color: #F5F5F5 !important;
}
.bg-green {
  color: #367A59 !important;
  border-color: #367A59 !important;
  background-color: #B8DBCA !important;
}
.bg-red {
  color: #BD251C !important;
  border-color: #BD251C !important;
  background-color: #EEB4B0 !important;
}
.bg-light-red {
  background-color: #FFF3F3 !important;
}
.bg-light-yellow {
  background-color: #FFFBF0 !important;
}
.bg-light-green {
  background-color: #E8FFF3 !important;
}
.bg-light-blue {
  background-color: #F1FAFF !important;
}
.bg-light-subdetail {
  background-color: #F9FAFB !important;
}
.bg-light-grey {
  background-color: #F7F8FF !important;
}

.border-dashed {
  border: 1px dashed #C2C2C2;
}

.inner-addon, .inner-addon-sm {
  position: relative;
}

/* style icon */
.inner-addon .fas {
  position: absolute;
  padding: 12px;
  font-size: 14px;
}
.inner-addon .fa {
  position: absolute;
  padding: 12px;
  font-size: 13px;
}
.inner-addon-sm .fa {
  position: absolute;
  padding: 8px;
  font-size: 12px;
}
.inner-addon .btn {
  position: absolute;
  padding: 7px;
  font-size: 13px;
}
.inner-addon span {
  position: absolute;
  padding: 6px 4px;
  margin: 3px;
  font-size: 13px;
}
.inner-addon-sm span {
  position: absolute;
  padding: 4px;
  margin: 3px;
  font-size: 12px;
}

/* align icon */
.left-addon .fas, .btn  { left:  0px; }
.right-addon .fas, .btn { right: 0px; }

.left-addon .fa, .btn  { left:  0px; }
.right-addon .fa, .btn { right: 0px; }

.left-addon-span span { left:  0px; }

/* add padding  */
.left-addon input { padding-left: 35px; }
.right-addon input { padding-right: 45px; }

.left-addon-span input { padding-left: 30px; }

@media (min-width: 600px) {
  .snackbar-notif, .snackbar-success, .snackbar-danger, .snackbar-warning, .snackbar-info {
    margin: 0 !important;
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
}

.mat-dialog-override {
  height: 0px;
  mat-dialog-container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }
}

.notif {
  box-shadow: 0 2px 8px rgba(61, 63, 86, 0.08);
}
.notif .bg-notif {
  background: #DCDDF9;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.notif .bg-light {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}